import React, { useContext } from 'react';
import { MenuContext } from '../../../context'

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data'

const Article = () => {
  const menuContext = useContext(MenuContext);

  return (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
      <p>With effect from Monday 15th March 2021, we will be making the following changes to our mortgage product range:</p>
      <p><strong>Summary of changes</strong></p>
      <ul>
        <li>We are increasing the lending limit on our 85% LTV range from £500,000 to £750,000</li>
        <li>We are increasing the lending limit on our 90% LTV range from £400,000 to £550,000</li>
      </ul>
      <p>There are no other changes to our rates or end dates.</p>
      <p>You can browse the range of HSBC mortgages using our <a href="/products/product-finder.html" inert={menuContext.isMenuOpen ? "true" : null}>product finder</a> or by downloading our <a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href} inert={menuContext.isMenuOpen ? "true" : null}>latest rate sheet<span className="accessible">&nbsp;This PDF opens in a new window</span></a>.</p>
    </NewsArticle>
  )
}

export default Article
